// region: PLACEHOLDER, IMPORTANT: don't remove
@import '~@delon/theme/system/index';
@import '~@delon/abc/index';
@import '~@delon/theme/layout-default/style/index';
@import '~@delon/theme/layout-blank/style/index';
@import './styles/index';
@import './styles/theme';
@import './styles/font';

.ant-modal-full {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
  }

  .ant-modal-full .full-screen {
    margin-right: 20px !important;
  }

  .ant-modal-full .ant-modal-content {
    width: 100% !important;
    height: 100%;
    margin: auto;
  }

  .ant-modal-full .ant-modal-content {
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .ant-modal-full .ant-modal-content {
    width: 100% !important;
    height: 100%;
    margin: auto;
  }

  .ant-modal-full .ant-modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .ant-modal-full.ant-modal {
    padding-bottom: 0 !important;
  }

  .ant-modal.ant-modal-full {
    top: 0 !important;
  }

  .ant-modal-full .ant-modal-body {
    overflow: auto;
    height: calc(100vh - 110px);
  }
  .fa-expand.full-screen {
    float: right;
    margin-right: 30px !important;
  }
  .ant-modal-title {
    margin-top: 4px;
  }

  .ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    /* color: rgba(0, 0, 0, 0.45); */
    color: black;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
  }


// .fa-expand:before {
//     content: "\f065";
// }

