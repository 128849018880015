// 建议所有项目自定义样式放在该文件夹下
@primary-color: #006ba8;
nz-table {
    width: 100% !important;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
    z-index: 2 !important;
}

.search {
    &__form {
        &.ant-form-vertical {
            .ant-form-item {
                margin-bottom: 16px;
                margin-right: 0;
                display: block;
            }

            .ant-form-item-label,
            .ant-form-item-control-wrapper {
                padding: 0 !important;
            }
        }

        margin-bottom: 0px !important;
    }
}

.text-disabled-color {
    color: rgba(0, 0, 0, 0.25);
}

.ant-alert {
    padding-right: 30px !important;
}

.modal-include-tabs .ant-alert {
    margin-bottom: 16px !important;
}

.alain-default__content nz-input-group {
    width: 100%;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-35 {
    margin-top: 35px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-bottom-35 {
    margin-bottom: 35px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

//.ant-form-item {
//    margin-bottom: 0
//}
//fiz nz Icon
.anticon svg {
    display: block;
}

//btn Custom
.btn-outline-success {
    color: #0cba70;
    border-color: #0cba70;
}

.no-select-text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

//region footer-fixed-page
@media (min-width: 768px) {
    .alain-default__content {
        .footer-fixed-page {
            position: fixed;
            bottom: 0;
            left: 200px;
            width: calc(100% - 200px);
            z-index: 1;
        }
    }
    .alain-default__collapsed .alain-default__content {
        .footer-fixed-page {
            left: 64px;
            width: calc(100% - 64px);
        }
    }
}
.alain-default__content {
    .footer-fixed-page {
        .content-footer {
            width: 100%;
            margin-right: 24px;
            margin-left: 24px;
        }
    }
}

.footer-fixed-page-60 {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #193550;
}
.kt-font-danger {
    background: #f00;
    color: #fff;
    font-size: 14px;
    padding: 4px 5px;
    border-radius: 4px;
}
.kt-font-success {
    background: #007bff;
    color: #fff;
    font-size: 14px;
    padding: 4px 5px;
    border-radius: 4px;
}
.kt-font-warning {
    background: #fbbc05;
    color: #fff;
    font-size: 14px;
    padding: 4px 5px;
    border-radius: 4px;
}

.page-header__title,
.page-header__action {
    margin-bottom: 0px;
}

.ora-form-case {
    margin: 10px 0px 10px 0px !important;
    text-align: center;
}

.btn-form-case {
    margin: 0px 5px 0px 5px !important;
}

.padding-0 {
    padding: 0;
    background: white !important;
}
.radion-custom {
    padding: 1em;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 0px;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0px 0;
}
.ant-table-thead > tr > th {
    color: #425a70;
    font-weight: 500;
    text-align: left;
    background: @primary-color;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}

p {
    margin-top: 2px;
    margin-bottom: 2px;
}

a {
    color: @primary-color;
    &:hover {
        color: #0667ff;
    }
}

.text-primary {
    color: #035f96 !important;
}

.ant-input-affix-wrapper .ant-input-clear-icon {
    opacity: 0;
}
.ant-input-affix-wrapper:hover .ant-input-clear-icon {
    opacity: 1;
}

.actionButtonForm {
    text-align: right !important;
}
.ant-tag {
    margin: 0px 8px;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: unset;
}
.ant-select-multiple .ant-select-selection-item-remove {
    line-height: 1.7;
}